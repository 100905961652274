<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Acknowledgement">
      <div
        class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-x-6 gap-4"
      >
        <asom-form-field :label="'Acknowledge Status'">
          <asom-input-radio-group
            class="mt-3"
            horizontal
            v-model="filters.IsAcknowledged"
            :options="IsAcknowledgedOptions"
          />
        </asom-form-field>
        <asom-form-field label="Expiry Status">
          <asom-input-radio-group
            class="mt-3"
            horizontal
            v-model="filters.IsExpired"
            :options="IsExpiredOptions"
          />
        </asom-form-field>
        <asom-form-field>
          <asom-input-checkbox
            v-model="filters.IncludePastThreeMonthsData"
            label="View all Acknowledgements for past 3 months"
          />
        </asom-form-field>
        <asom-input-text
          v-model="filters.search"
          :loading="isLoading"
          @search-change="getPageData(true)"
          placeholder="Type to search for Acknowledgements"
          />
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-card title="Personal Acknowledgements">
        <asom-client-table
          class="mt-4"
          v-if="!isLoading"
          :columns="[
            'cpUpdateNo',
            'isAcknowledged',
            'cpUpdateTitle',
            'description',
            'acknowledgeByDate',
            'dateOfAckNowledgement',
          ]"
          :sortableColumns="[
            'cpUpdateNo',
            'isAcknowledged',
            'cpUpdateTitle',
            'description',
            'acknowledgeByDate',
            'dateOfAckNowledgement',
          ]"
          :data="itemList"
          :filterable="false"
        >
          <template v-slot:header_cpUpdateNo>CP Update No.</template>
          <template v-slot:header_isAcknowledged>Status</template>
          <template v-slot:header_cpUpdateTitle>CP Update Name</template>
          <template v-slot:header_acknowledgeByDate
            >To Be Acknowledged By</template
          >
          <template v-slot:header_dateOfAckNowledgement
            >Acknowledgement Date</template
          >
          <template v-slot:cpUpdateNo="scopedSlots">
            <button
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ scopedSlots.data}}
            </button>
          </template>
          <template v-slot:isAcknowledged="scopedSlots">
            {{ acknowlegementStatus(scopedSlots.rowData) }}
          </template>
          <template v-slot:dateOfAckNowledgement="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
        </asom-client-table>
        <div v-else class="text-center">
          <asom-icon icon="spinner" class="animate-spin" />
        </div>
      </asom-card>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { listPersonalAcknowledgement } from "../../../services/opsComms.service";
import moment from "moment";
import { setStorage, getStorage } from "@/helpers/sessionStorage";


export default {
  data() {
    return {
      isLoading: false,
      itemList: [],
      error: null,
      filters: {
        search: "",
        IsAcknowledged: false,
        IsExpired: false,
        IncludePastThreeMonthsData: false,
      },
    };
  },
  mounted() {
    const searchVal = getStorage("acknowledge-ments");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.getPageData();
    })
  },
  computed: {
    IsAcknowledgedOptions() {
      return [
        { value: true, label: "Acknowledged" },
        { value: false, label: "Not Acknowledged" },
      ];
    },
    IsExpiredOptions() {
      return [
        { value: true, label: "Expired" },
        { value: false, label: "Not Expired" },
      ];
    },
    queryParams() {
      let params = {};
      params["isAcknowledged"] = this.filters.IsAcknowledged;
      params["isExpired"] = this.filters.IsExpired;
      params["title"] = this.filters.search;
      params["includePastThreeMonthsData"] = this.filters.IncludePastThreeMonthsData;
      return params;
    },
  },
  watch: {
    'filters.search': function(){
      if(this.filters.search.length >= 3 || this.filters.search.length == 0)
        this.getPageData();
    },
    'filters.IncludePastThreeMonthsData': function(){
      if(this.filters.IncludePastThreeMonthsData === false)
        this.resetFilters();
    }
  },
  methods: {
    get,
    displayUtcDate,
    resetFilters() {
      this.filters.IsAcknowledged = null;
      this.filters.IsExpired = null;
      this.filters.search = null,
      this.filters.IncludePastThreeMonthsData = null;
    },
    acknowlegementStatus({ isAcknowledged, cpUpdateDetails }) {
      const expiryDate = moment(get(cpUpdateDetails, "expiryDate"));
      const today = moment(Date.now());
      const isExpired =
        expiryDate.isValid() && expiryDate.isBefore(today, "day");
      if (isAcknowledged) return "Acknowledged";
      else if (isExpired) return "Expired";
      else {
        return "Pending Acknowledgement";
      }
    },
    async getPageData(status) {
      if(status) setStorage("acknowledge-ments", this.filters);
      this.isLoading = true;
      const resp = await listPersonalAcknowledgement({
        ...this.queryParams,
      });
      if (resp.success) {
        this.itemList = get(resp.payload, "model", []).map(item => ({
          ...item,
          cpUpdateNo: get(item, "cpUpdateNo"),
          acknowledgeByDate: get(
            item,
            "acknowledgementDeadlineDate"
          )
            ? displayUtcDate(
                get(
                  item,
                  "acknowledgementDeadlineDate"
                )
              )
            : "NA",
            description: get(item, "cpUpdateDescription", "NA")
        }));
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View Acknowledgement",
        params: selectedItem,
      });
    },
  },
};
</script>
